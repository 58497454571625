@import '../../../styles/fontSizes.scss';
@import '../../../styles/colors.scss';

.CAKE__detailed-analytics-information {
  padding-top: 20px;

  &__top-container {
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__download-btn {
    display: flex;
    align-items: flex-end;
    font-size: 12px !important;
    color: $v2-primary;
    cursor: pointer;
    width: 100%;
  }
}