.CAKE__table-container {
  padding: 0;
  overflow: auto;

  &__header {
    font-weight: 800;
  }

  &__actions {
    justify-content: flex-end;
  }

  &__no-data {
    margin-top: 20px;
    padding: 0 16px 10px 24px;
    display: flex;
    justify-content: center;
  }
}