@import '../../../styles/fontSizes.scss';
@import '../../../styles/colors.scss';


.CAKE__alert-banner {

&__update-header {
    
  &__container {
    padding: 5px 0;    
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: $v2-primary;

    &--error {
       background-color: $banner-error;
    }
  }

  &__text {
    color: $white
  }    

  &__button {
    background-color: $v2-primary;
    color: $white;
    border: solid white 1px;
  }

  &__button:hover {
    background-color: white;
    color: $v2-primary;
    border: solid white 1px;
  }

}
}