@import '../../../styles/colors.scss';
@import '../../../styles/fontSizes.scss';

.CAKE__builder-image-upload {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;

  img {
    max-width: 100%;
  }

  &__image {
    position: relative;
    border: 4px dashed transparent;
    border-radius: 12px;
    width: 75%;
    transition: border-color 0.5s ease-in-out;

    label {
      cursor: pointer;
      padding: 2rem;
      align-items: center;
      display: flex;
      justify-content: center;
    }
    
    img {
      border-radius: 12px;
      box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.2);
    }

    &--uploading {
      opacity: 0.5;
      pointer-events: none;
      animation: pulse 2s cubic-bezier(.4,0,.6,1) infinite;

      @keyframes pulse {
        50% {
          opacity: .5;
        }
      }
    }

    &--error {
      border-color: $input-error-border;
    }

    &--active,
    &:hover {
      border-color: $border;

      &:before,
      &:after {
        opacity: 1;
      }

      .CAKE__builder-image-upload__image__message {
        opacity: 0;
      }
    }

    &:before {
      content: "+";
      background-color: $v2-background;
      font-size: 36px;
      width: 3rem;
      height: 1rem;
      bottom: -6px;
      color: $border;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
    }

    &:before {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      transition: opacity 0.5s ease-in-out;
    }
  }

  &__input {
    width: 100%;
    height: 100%;
  }
}