@import '../../../styles/colors.scss';

.CAKE__segmented-button {
  display: flex;


  &__button {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
    background-color: $white;
    border: 1px solid $border-gray;
    font-weight: 800;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-radius: 12px 0 0 12px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-radius: 0 12px 12px 0 ;
    }
    &:not(:last-child) {
      border-right: none;
    }

    &--active {
      background-color: $background-dark;
      border: 1px solid $background-dark;
      color: $white;
    }
  }
}