@import '../../../styles/colors.scss';

.CAKE__collection-item-image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &--with-dropzone {
    height: 100%;
  }

  &--card {
    padding: 0 24px 0 24px;
  }

  &__image {
    object-fit: contain;
    width: 100%;
    height: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    &--card {
      height: 100%;
      object-fit: contain;
    }
  }

  &__no-image {
    position: absolute;
    width: 100%;
    height: calc(100% - 90px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 90px;
    color: $disabled-transparent;
  }

  &__dropzone {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
    font-size: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px dashed $border-gray;
    transition: 300ms all linear;

    &--active {
      background-color: #FFFFFFAA;
      opacity: 1;
    }
  }
}