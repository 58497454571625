@import '../../../styles/colors.scss';
@import '../../../styles/fontSizes.scss';

.CAKE__upload-avatar-field {
  position: relative;
  align-items: center;

  &__title {
    font-size: $xs;
    align-self: center;
    color: $input-title;

  }

  &__image {
    cursor: pointer;
  }

  &__input-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 10px;
    // max-width: 100%;
    height: fit-content;
    min-height: 36px;
    position: relative;

    &--dense {
      border: none;
      min-height: 20px;
    }
  }
}

textarea::-webkit-scrollbar {
  width: 4px;
}

textarea::-webkit-scrollbar-thumb {
  border-radius: 2px;
}