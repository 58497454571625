@import '../../../styles/colors.scss';

.CAKE__stepper {
  &__step {
    cursor: pointer;
    background-color: $border;
    height: 4px;
    border-radius: 40px;
    width: 40px;

    &-completed {
      background-color: $v2-primary;
    }
  }
}