@import '../../../../../styles/colors.scss';

.CAKE__project-table__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 40px;
  height: 455px;
  cursor: pointer;
  background-color: $white;
  border-radius: 14px;
  gap: 40px;

  transition: 0.3s background-color linear;

  &:hover {
    background-color: $button-background-hover;
  }

  &__image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $background-dark;
    height: 375px;
    width: 375px;
    border-radius: 14px;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.2);

    > img {
      object-fit: cover;
      clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%);
      width: inherit;
      height: inherit;
      border-radius: 14px;
    }
  }

  &__status {
    position: absolute;
    left: 20px;
    top: 20px;
  }

  &__container {
    color: $font-subtitle;
  }

}