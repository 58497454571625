@import '../../../styles/colors.scss';

.CAKE__collection-advanced-settings-modal {
&__title {
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--upload {
    padding: 2px 6px;
    cursor: pointer;
    color: $v2-primary;
    display: flex;    
    justify-content: center;
    align-items: center;
    border-radius: 16px;
  }

  &--upload:hover {
    background-color: #f4f3f3;
  }
}

  &__info {
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__dropzone {
    display: flex;    
    justify-content: center;
    padding-top: 60px;
    cursor: pointer;
  }
}