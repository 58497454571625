@import'~styles/colors.scss';

.CAKE__value-box {
  border: 1px solid $border-gray;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 15px;

  &__label-container {
    display: flex;
    flex-direction: row;
    align-items: center;;
  }
  &__label {
    font-weight: 700;
    font-size: 1rem;
    color: $font-header-gray;
    margin-bottom: 4px;
    text-align: left;
  }

  &__sublabel {
    color: $font-header-gray;
    padding-left: 4px;
    text-align: left;
  }

  &__tooltip {
    padding-left: 4px;
    cursor: pointer;

    &__icon {
      color: $font-header-gray !important;
    }
  }

  &__value-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    padding: 8px;

    &__value {
      font-weight: bold;
      font-size: 2rem;
      margin-right: 8px;
      color: #333;
    }

    &__change-indicator-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 1px solid black;
      border-radius: 12px;
      height: 20px;
      padding: 0 6px;
      min-width: 20px;
    }

    &__change-indicator {
      font-size: 2px;
      margin-right: 2px;

      > svg {
        font-size: 0.9rem;
      }
    }

    &__change-factor {
      font-size: 0.8rem;
      font-weight: 800;
      color: #666;
    }
  }
}