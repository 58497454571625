@import '../../../styles/colors.scss';

.CAKE__page-layout {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  
  &__left-container {
    width: 200px;
    min-width: 200px;
    border-right: 1px solid $primary;
    padding: 20px;
  }

  &__right-container {
    width: 100%;
    padding: 20px;
  }
}