@import '../../../../styles/colors.scss';

.CAKE__contract-transaction-item {
  &__link {
    text-decoration: none;
    color: inherit;
  }

  &__user-wallet {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 4px;

    p {
      margin: 10px 0 10px 10px;
    }

    &__link {
      text-decoration: none;
      color: inherit;
    }
  }

  &__pill {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border: 1px solid $font-subtitle;
    border-radius: 16px;
    height: 21px;
    width: 52px;
  }
}