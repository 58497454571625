$primary: #000000;
$secondary: #666666;

$primary-inverted: #FFFFFF;
$primary-teal: hsl(174, 100%, 29%);
$secondary-teal: #b2dfdb;

$disabled: #AAAAAA;
$disabled-transparent: #AAAAAA44;

$background: #e5f7f6;
$card-shadow: #004d4022;

$input-border: #b9e7e5;
$input-title: #26a69a;
$input-text: #00695c;
$banner-error: #f44336;
$input-error-border: #f44336;
$input-error-title: #df281b;
$input-error-text: #f66156;
$banner-error: #df281b;

/*** New Design ***/
$border: rgba(0, 0, 0, 0.15);
$button-background-active: rgba(239, 239, 239, 0.75);
$button-background-hover: rgba(239, 239, 239);
$button-font: #656565;
$button-font-active: #1A1D1F;
$button-disabled: #C8C8C8;

// bacgrounds
$background-dark: #1A1A1A;
$tooltip-background: #FFFFFFCC;

// borders
$border-gray: #CCCCCC;
// fonts
$font-header-gray: #8D8D8D;
$font-header: #272B30;
$font-subtitle: #131313;
$font-body: #272B30;

// general
$white: #FFFFFF;

// status
$status-new: #14FF00;

$v2-background: #E7E7E7;
$v2-primary: #599CFF;
$v2-input-border: #b9e7e5;
$v2-input-title: #26a69a;
$v2-input-text: #00695c;

//text
$label: #AAAAAA;
$label-strong: #999999;
