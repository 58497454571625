@import '../../../styles/colors.scss';
@import '../../../styles/radius.scss';
@import '../../../styles/shadows.scss'; 

.CAKE__project-shopify-auth {
  width: 100%;
  height: 100%;
  background: url(../../../../public/background.png) no-repeat center fixed;   
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  &__emphasize {
    color: $font-header-gray;
    display: inline;
    font-style: italic;
  }

  &__container {
    width: 500px;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    background: white;
    padding: 2rem;
    border-radius: $rounded-md;
    box-shadow: $shadow;
  }
}