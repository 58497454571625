@import '../../../styles/colors.scss';

.CAKE__page-layout {
  height: 100%;
  min-height: 100%;

  &__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: calc(100% - 8px);
    padding-top: 8px;
  }

  &__inner-container {
    max-width: 1200px;
    width: 100%;
    height: 100%;
  }
}