@import '../../../../../styles//colors.scss';

.CAKE__collection-viewer-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;

  &__left-container {
    height: inherit;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-bottom: 12px;
    padding-right: 12px;
    flex: 1;
  }

  &__right-container {
    height: inherit;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-left: 12px;
    padding-bottom: 12px;
    gap: 2px;
    flex: 1;
  }
}