@mixin block($size: 157px, $radius: 20px, $padding: 20px) {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start; 
  flex-direction: column;
  width: $size;
  height: $size;
  max-width: $size;
  cursor: pointer;
  padding: $padding;
  border-radius: $radius;
  border: 1px solid $border;
}