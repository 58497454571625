@import '../../../styles/radius.scss';
@import '../../../styles/shadows.scss';

.CAKE__modal {
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  
  border-radius: $rounded-md;
  padding: 16px;
  box-shadow: $shadow;

  &__header {
    display: flex;
    flex-direction: column;    
    top: 24px;
    left: 24px;
    right: 24px;
    margin-bottom: 10px;
  }

  &__content {
    position: absolute;
    bottom: 80px;
    left: 24px;
    right: 24px;
    top: 92px;

    &__no-header {
      top: 16px;
    }

    &__no-subheader {
      top: 72px;
    }

    &__no-footer {
      bottom: 24px;
    }
  }

  &__footer {
    position: absolute;    
    padding-top: 5px;
    bottom: 7px;
    left: 24px;
    right: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
}