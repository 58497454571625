@import '../../../styles/colors.scss';

.CAKE__menu-drawer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: inherit;
  &__container {
    width: 88px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    transition: 0.7s all 0.5s;


    &--open {
      width: 242px;
    }
  }
}