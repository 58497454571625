@import '../../../styles/colors.scss';
@import '../../../styles/fontSizes.scss';
@import '../../../styles/components.scss';

.CAKE__image-dropzone {
  @include block($size: 205px, $padding: 0);

  justify-content: center;
  align-items: center;
  width: 205px;
  height: 205px;
  max-width: 205px;
  color: $font-subtitle;
  font-size: 32px;
  border-radius: 20px;
  position: relative;

  &--small {
    @include block($size: 60px, $radius: 14px, $padding: 0);

    font-size: 28px;
    justify-content: center;
    align-items: center;
  }

  &--active {
    opacity: 0.5;
  }

  &__info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 16px;
    width: inherit;
    height: inherit;
    background-color: white;
    border-radius: 20px;
    transition: opacity 0.2s;

    &--small {
      border-radius: 14px;
      padding-top: 0;
    }

    &--with-image {
      opacity: 0.8;
    }

    &--hidden {
      opacity: 0;
    }
  }

  &__title {
    padding-top: 12px;
    margin: 0;
    font-size: $m;
    font-weight: 600;
  }
  &__size-recommendation {
    margin-top: 4px;
    font-size: $s;
  }

  &__image {
    &--container {
      position: absolute;
      display: block;
      margin-left: auto;
      margin-right: auto;  
      display: flex;
      justify-content: center;
    }

    &--image {
      max-width: 90%;
      max-height: 90%;
      aspect-ratio: auto;
    }    
  }

  &__upload {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 16px;
    width: inherit;
    height: inherit;
    background-color: white;
    border-radius: 20px;
    transition: opacity 0.2s;

    &--small {
      padding-top: 0;
    }
  }

  &__delete {
    cursor: pointer;
    position: absolute;
    top: 4px;
    left: 4px;
    opacity: 0;
    z-index: 2;

    &--mouseover {
      transition: 400ms;
      opacity: 1;
    }
  }
}