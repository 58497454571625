@import '../../../styles/colors.scss';

.CAKE__info-item-input {
  input {
    all: unset;

    &:focus {
      color: $v2-primary;
    }
  }
}