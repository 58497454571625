@import '../../../styles/fontSizes.scss';
@import '../../../styles/colors.scss';

.CAKE__autocomplete-multi-select {
  &__title {
    font-size: $xs;
    padding-left: 8px;
    padding-bottom: 3px;
    color: $input-title;
  }
}