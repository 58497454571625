@import '../../../../../styles/fontSizes.scss';
@import '../../../../../styles/colors.scss';

.CAKE__content-item__documents-settings {
  
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 20px 0;

  &__title {
    font-size: $l;
    font-weight: 600;
    color: $primary-teal;
    padding: 0px; 
    margin: 0;     
  }

  &__subtitle {
    font-size: $m;
    color: $primary-teal;      
    padding: 0px;
    margin: 0;
  }

}