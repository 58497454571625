@import '../../../styles/colors.scss';

.CAKE__project_timeline {  

  width: 100%;
  height: calc(100% - 20px);

  &__container {    
    width: 100%;
    overflow-wrap: anywhere; 
    padding-top: 20px      
  }  

  &__notification {
    position: fixed;    
    z-index: 10;    

    &-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5%;
    }    

    &-button{
      width: 100%
    }

  }

  &__activityCard{

    &__comments__container{
      padding-left: 20px;
    }

    &__content {      

      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-left: 5%;
      padding: 10px;  

      &--activityLink {
        text-decoration: none;
        font-size: small;
        color:darkslategray;
      }

      &-post{
        &-image {
          margin-top: 5px;
        }
      }      

      &-transfer-details-container {
        margin: 10px 0;
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: space-around;
        align-items: center;
      }

      &-transfer-image-container {
        margin: 10px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
      }

      &-transfer-details {                
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;          
      }  

      &-link {
        text-decoration: none;
        color: grey;
      }      
    }    

    &__add-comment {
      
      &__container {
        display: flex;
        flex-direction: column;
        gap: 5px        
      }

      &__container button {
        width: fit-content;
      }

      &__text-input {
        width: 100%;        
      } 
      
      &-button-container {
        display: flex;
        flex-direction: row;
        gap: 5px;
      }
    }

    &__reaction-icon {
      display: flex;
      gap: 5px;
    }
  }
}