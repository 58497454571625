@import '../../../styles/colors.scss';

.CAKE__image-generations-grid {
  height: 100%;
  width: 100%;
  padding-top: 100px;

  &__item {
    width: 100%;
    border-radius: 15px;
    cursor: pointer;

    &:hover {
      filter: brightness(90%);
    }

    &__favourite {
      position: absolute;
      top: 25px;
      right: 11px;
      display: flex;
      flex-direction: row;
    }

    &--loading {
      opacity: 0.3;
    }

    &__error {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid $v2-primary;
      border-radius: 15px;

      &__label {
        font-size: 15px !important;
        font-weight: 600 !important;
        padding-bottom: 20px;
      }
    }
  }
}