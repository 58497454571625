@import '../../../../../styles/colors.scss';

.CAKE__collection-item-content {
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
  padding-right: 10px;

  &__image {
    width: 50%;
    height: 100%;
    position: relative;
    &--no {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid $disabled-transparent;
      border-radius: 4px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__description-input {
    max-height: 100px;
    height: 100px;
  }

  &__properties {
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    padding-left: 32px;
    width: 50%;
    gap: 24px;
    height: inherit;
    height: 512px;
  }
  &__traits {
    display: flex;
    flex-direction: column;
    max-height: 220px;
    padding-top: 0px;
    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $border-gray;
    }
  }
}