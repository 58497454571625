@import '../../../styles/colors.scss';
@import '../../../styles/fontSizes.scss';

.CAKE__status-chip {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
  padding: 0 8px;
  background: $status-new;
  border-radius: 8px;

  > p {
    line-height: 12px;
    font-size: $xs;
    letter-spacing: 0.04em;
    font-weight: 500;
    font-family: 'Inter';
  }
}