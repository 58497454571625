.CAKE__collection-artworks-card {
  width: 100%;
  height: calc(100% - 20px);
  padding-bottom: 0;

  &__controls-container-padding {
    padding-top: 20px;
  }

  &__controls-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 80px;
    width: 100%;
  }

  &__inner-container {
    height: calc(100% - 200px);
    width: 100%;
  }

  &__viewer-container {
    height: 100%;
    width: 100%;
  }

  &__container-content {
    margin-top: 20px;
  }

  &__link {
    text-decoration: underline;
    cursor: pointer;
  }
}