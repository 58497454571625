@import '../../../styles/colors.scss';

.CAKE__image-generation-modal {
  &__image {
    border-radius: 15px;
  }

  &__title {
    font-size: 16px !important;
    padding-bottom: 4px;
  }

  &__field {
    font-size: 14px !important;
    width: 100%;
    margin-bottom: 20px !important;
    text-align: justify;

    &__walletAddress {
      font-size: 14px !important;
      width: fit-content;
      padding: 2px 6px;
      border-radius: 24px;
      background-color: #f3f3f3;
      cursor: pointer;

      &:hover {
        filter: brightness(90%);
      }
    }

    &__prompt {
      margin-bottom: 0px !important;
      padding: 2px 6px;
      border-radius: 24px;
      font-size: 10px !important;
      width: fit-content;
      background-color: transparent;
      border: solid 1px black;
      color: black;
    }
  }
}