.CAKE__image-generation-empty-state {
  width: 100%;
  height: calc(100% - 200px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__label {
    font-size: 20px !important;
    font-weight: 600 !important;
    padding-bottom: 20px;
  }
}