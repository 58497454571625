@import '../../../../../styles/colors.scss';
@import '../../../../../styles/fontSizes.scss';

.CAKE__vrf-confirm-modal {  
  

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;    
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__link {
    color: $v2-primary;
  }

  &__content {
    overflow-y: scroll;
  }

  &__error {
    margin-top: 10px;
    color: $input-error-text
  }
}

code {
  background-color: lightgray;
  border-radius: 16px;
  padding: 1px 3px;
}