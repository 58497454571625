@import "../../../styles/fontSizes.scss";

.CAKE__wallet-connect-button__modal {

  border-radius: 16.25px;

  &__option{

    &__container {    
      padding: 20px;
      cursor: pointer;
      width: 100%;
      align-items: center;      
    }

    &__container:nth-child(even) {    
      border-top: 1px solid rgba(0, 0, 0, 0.15);      
    }

    &__container:hover {
      background-color:rgba(0, 0, 0, 0.05);
    }

    &__text {
      font-size: 36px;
      font-weight: 600;
    }
  }
}