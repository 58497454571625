@import  '../../../styles/colors.scss';

.CAKE__item-loader {

  &__container {
    width: 100%;
    height: 100%;

    &--with-background {
      background: url(../../../../public/background.png) no-repeat center fixed;
      background-size: cover;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
    }
  }

  &__image {

    &--container {
      position: relative;
      display: inline-flex;
      color: $v2-primary;
    }

    &--logo {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

}