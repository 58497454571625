@import "../../../styles/colors.scss";

.CAKE__wallet-connect-button__modal {
  &__status {
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;     
    background-color: $status-new;    
  }
}