@import '../../../styles/colors.scss';
@import '../../../styles/radius.scss';
@import '../../../styles/components.scss';

.CAKE__template-settings-card {
  &__template {
    @include block;

    justify-content: space-between;

    &--selected {
      border: 2px solid $v2-primary;
    }
  }

  &__preview {
    width: 100%;
    height: auto;
    border-radius: $rounded-sm;
  }
}