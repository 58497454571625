.CAKE__collection-viewer {
  height: 100%;

  &__container {
    height: calc(100% - 68px);
    padding-top: 8px;
  }

  &__loading-container {
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}