@import '../../../../../styles/fontSizes.scss';
@import '../../../../../styles/colors.scss';

.CAKE__content-item__markdown-settings {
  
  &__container {
    margin-top: 10px;
  }

  &__editor {
    width: 100%;
    height: 500px;
    border-radius: 10
  }
}