@import '../../../styles/colors.scss';

.CAKE__plugin-switch {

  &__container {
    border: 1px solid lightgray;
    border-radius: 16px;
    padding: 25px;
  }

  &__image {
    max-height: 105px;
    object-fit: contain;
  }

  &__buttons-container {
    padding: 10px 0;
  }

  &__info-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  &__category-label {
    color: $label-strong;
  }

  &__divider {
    color: $label;
  }

  &__plan-label {
    color: $label;
  }
}