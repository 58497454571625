@import '../../../../../styles/colors.scss';

.CAKE__menu-drawer-header {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 90px;
  padding-left: 16px;

  &__home {
    cursor: pointer;
  }
}