@import '../../../../../styles/fontSizes.scss';
@import '../../../../../styles/colors.scss';

.CAKE__content-item__basic-settings {
  &__status {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;

    &__title {
      font-size: $l;
      font-weight: 600;
      color: $primary-teal;
      padding: 0px; 
      margin: 0;     
    }

    &__pill {      

      &--published {
        background-color: green;
        padding: 4px 8px;
        border-radius: 12px;
        color: white;
      }

      &--draft {
        background-color: rgb(241, 95, 95);
        padding: 4px 8px;
        border-radius: 12px;
        color: white;
      }
    }
  }
}