@import '../../../styles/colors.scss';

.CAKE__dashboard-card {
  border-radius: 4px;
  padding-bottom: 68px;
  min-height: 60px;

  &__controls-container-padding {
    padding-top: 20px;
  }

  &__title {
    color: $font-header;
    font-family: 'Inter';
  }

  &__subtitle {
    color: $font-subtitle;
  }

  &__header-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
  }

  &__container {
    padding: 8px 24px;
    height: calc(100% - 64px);
  }
}