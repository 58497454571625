@import '../../../styles/fontSizes.scss';
@import '../../../styles/colors.scss';


.CAKE__contract-deployment-card {

  &__controls-container-padding {
    padding-top: 20px;
  }

  &__title {
    justify-content: space-between;
    align-items: center;
  }

  &__controls-title {
    align-items: center;
    padding-top: 80px;
  }

  &__controls-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 30px;
    width: 100%;
  }

  &__contract-type {
    font-size: $xs;

    &__container {
      width: 100%;
    }

    &--title {
      font-size: $sm;
      color: rgba(0, 0, 0, 0.6)
    }
  }

  &__payment-split {

    &__container {
      box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.42);
      transition: all 0.15s;
    }

    &__container:hover {
      box-shadow: inset 0px 0px 0px 2px rgba(0, 0, 0, 0.7);
    }

    &__inner-content {
      padding: 15px;
    }

    &__header {
      font-size: $sm;
      font-weight: 500;
    }
  }
}