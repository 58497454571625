@import '../../../styles/colors.scss';

.CAKE__collection-token-group-modal {
  height: 600px;

  &__content {
    display: flex;
    flex-direction: row;
    padding-right: 10px;
    gap: 16px;

    &__left {
      display: flex;
      width: 50%;
      height: 457px;

      &__inner {
        position: relative;
        display: flex;
        height: 100%;
        width: 100%;
      }
    }

    &__right {
      width: 50%;
      display: flex;
      flex-direction: column;
      padding-top: 8px;
      gap: 24px;
      max-height: 457px;
    }
  }

  &__traits {
    display: flex;
    flex-direction: column;
    max-height: 215px;
    padding-top: 0px;
  }
}