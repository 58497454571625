@import '../../../styles/fontSizes.scss';

.CAKE__auth-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: url(../../../../public/background.png) no-repeat center fixed;   
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;    

  &__content {
    align-items: center;
    display: flex;
    flex: 1;
  }
  
  &__copyright {
    color: white;
    padding: 2rem;
    font-size: $xs;
  }
}
