@import '../../../styles/colors.scss';
@import '../../../styles/radius.scss';

.CAKE__signup-view {
  &__error {
    color: $primary-inverted;
    background-color: $input-error-border;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.5rem;
    width: 100%;
  }
}