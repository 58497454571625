.CAKE__collection-token-groups {
  // height: calc(100% - 24px);
  height: 100vh;
  min-height: calc(100% - 24px);
  padding-top: 24px;

  &__accordion-container {
    height: calc(100% - 56px);
  }

  &__controls-container {
    padding-top: 8px;
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}