@import '../../../styles/colors.scss';
@import '../../../styles/fontSizes.scss';

.CAKE__file-upload-input {
  &__title {
    font-size: $xs;
    padding-left: 8px;
    padding-bottom: 3px;
    color: $input-title;
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 10px;
    border-radius: 4px;
    border: 1px solid $input-border;
    height: fit-content;
    min-height: 36px;
    position: relative;

    &__file-path {
      max-width: calc(100% - 20px);
      padding-right: 30px;
      margin-right: 5px;
      overflow: auto;
      white-space: nowrap;
      font-size: $m;
      color: $input-text;
      border: none;
      scrollbar-width: none;

      &::-webkit-scrollbar { 
        display: none;
      }
    }
  }
  
  &__buttons-container {
    background-image: linear-gradient(to right, rgba(255,0,0,0) , rgba(255,255,255,1) 20%);
    position: absolute;
    right: 11px;
    bottom: 0px;
    top: 1px;
    width: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }
}