@import '../../../styles/colors.scss';
@import '../../../styles/fontSizes.scss';

.CAKE__boolean-slider-field {
  position: relative;

  &__title {
    font-size: $xs;
    padding-left: 8px;
    padding-bottom: 3px;
    color: $v2-primary;
  }

  &__input-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 10px;
    height: fit-content;
    min-height: 36px;
    position: relative;

    &--dense {
      border: none;
      min-height: 20px;
    }
  }

  &__error-message {
    font-size: $xs;
    padding-left: 8px;
    padding-top: 2px;
    color: $input-error-title;
    position: absolute;
    bottom: -12px;

    &--dense {
      position: absolute;
      left: 3px;
      bottom: -16px;
    }
  }
}

textarea::-webkit-scrollbar {
  width: 4px;
}

textarea::-webkit-scrollbar-thumb {
  background-color: #80cbc4;
  border-radius: 2px;
}