@import '../../../styles/colors.scss';

.CAKE__project-builder-view {
  margin-top: 0 !important;
  height: 100%;

  &__left {
    border-right: 1px solid $border;
    position: relative;

    .CAKE__stepper {
      margin-bottom: 2rem;
    }

    &__content {
      position: relative;
      padding: calc(var(--logo-height) + (var(--logo-padding) * 2) + 5vh) 2rem;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      margin: 1rem;
      position: absolute; 
      bottom: 0;
      left: 2rem;
      width: calc(100% - 5rem);
    }
  }

  &__right {
    background-color: $v2-background;
    padding-left: 0 !important;
    padding-top: 0 !important;
  }
}