.CAKE__project-community-view {
  width: 100%;
  height: calc(100% - 20px);
  padding-bottom: 0;

  &__controls-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 80px;
    width: 100%;
  }

  &__tabs {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 12px;
  }

  &__content {
    height: calc(100% - 220px);
    width: 100%;
  }
}

// .CAKE__collection-artworks-card {




//   &__inner-container {
//     height: calc(100% - 200px);
//     width: 100%;
//   }

//   &__viewer-container {
//     height: 100%;
//     width: 100%;
//   }

//   &__container-content {
//     margin-top: 20px;
//   }

//   &__link {
//     text-decoration: underline;
//     cursor: pointer;
//   }
// }