@import '../../../styles/colors.scss';
@import '../../../styles/fontSizes.scss';

.CAKE__file-dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 90px);
  height: 150px;
  font-weight: 500;
  outline: 1px dashed $v2-primary;
}