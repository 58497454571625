@import '../../../styles/colors.scss';

.CAKE__view-type-switch {
  &__button {
    &--active {
      > svg {
        > * {
          fill: $button-disabled;
          stroke: $button-disabled;
        }
      }
    }
  }
}