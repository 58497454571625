.CAKE__no-menu-layout {
  align-items: stretch;
  background-color: white;
  font-weight: 500;
  display: flex;
  min-height: 100vh;
  width: 100%;

  --logo-height: 40px;
  --logo-padding: 2rem;

  &__image--logo {
    position: absolute;
    top: 0;
    left: 0;
    padding: var(--logo-padding);
    height: var(--logo-height);
    width: 117px;
  
    img {
      max-width: 100%;
    }
  }

  &__content {
    flex: 1;
    position: relative;
  }
}