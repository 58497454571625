@import '../../../styles/colors.scss';

.CAKE__traits-table {
  &__header {
    min-height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $border-gray;
  }

  &__delete-icon {
    padding: 6px 6px 6px 16px;
  }
}