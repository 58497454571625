@import '../../../styles/colors.scss'; 
@import '../../../styles/radius.scss'; 

.CAKE__multifactor-auth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__input-container {
    margin-top: 1rem;
  }

  &__input {
    width: 32px;
    height: 32px;
    background: $border;
    border: none;
    border-radius: $rounded-sm;
    text-align: center;
  }
}