@import '../../../../../styles/colors.scss';
@import '../../../../../styles/fontSizes.scss';

.CAKE__faq-settings-card-entry {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  &__text {
    max-width: calc(100% - 80px);
    overflow: hidden;
    white-space: wrap;
    font-size: $m;
    color: $font-subtitle;
    border: none;
  }

  &__title {
    white-space: wrap;
    font-size: $m;
    color: $font-subtitle;
    padding-bottom: 8px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}