.CAKE__collection-token-group-details {
  display: flex;
  flex-direction: row;
  height: 500px;
  padding-bottom: 24px;
  padding-top: 10px;
  gap: 16px;

  &__left-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-left: 24px;
    padding-top: 48px;
  }

  &__right-container {
    width: 50%;
    flex-direction: column;
    display: flex;
    align-items: flex-start;

    &__top {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
    }


  }

  &__buttons {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;

    &__end {
      padding-right: 50px;
    }
  }

  &__image-container {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-grow: 1;
    width: 100%;
  }

  &__traits {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: 100px;
  }
}