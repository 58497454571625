@import '../../../styles/colors.scss';
@import '../../../styles/radius.scss';

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: 'Druk Wide';
  font-weight: 300;
  src: url('./fonts/DrukWideMedium.woff2') format('woff2');
}

@font-face {
  font-family: 'Druk Wide';
  font-weight: 500;
  src: url('./fonts/DrukWideBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Druk Wide';
  font-weight: 700;
  src: url('./fonts/DrukWideHeavy.woff2') format('woff2');
}

@font-face {
  font-family: 'Druk Wide';
  font-weight: 900;
  src: url('./fonts/DrukWideSuper.woff2') format('woff2');
}

.CAKE__typography-settings-card {
  &__preview {
    border: 1px solid $border;
    border-radius: $rounded-sm;
    padding: 1rem;
    margin-top: 10px;

    &--loading {
      cursor: not-allowed;
      animation: pulse 2s infinite;
    }
  }
}

@keyframes pulse {
  0% {
    transform: opacity(0.5);
  }
  
  70% {
    transform: opacity(1);
  }
  
  100% {
    transform: opacity(0.5);
  }
}