.CAKE__component-loader {

  display: flex; 
  align-items: center; 
  justify-content: center;
  width: 100%;
  height: 100%;

  &__container {        
    background-color: transparent;
  }

  &__image {

    &--container {
      position: relative;
      display: inline-flex 
    }

    &--logo {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__title {
    color: white
  }
  
}