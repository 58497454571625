@import '../../../styles/colors.scss';

.CAKE__project-header {
  display: flex;
  flex-direction: row;
  width: 100%;

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    border: 1px solid $border;
    width: 60px;
    height: 60px;
    max-width: 60px;
    margin-right: 12px;

    > img {
      width: 50px;
      height: 50px;
      max-width: 50px;
    }
  }

  &__name {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__editable {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
  }
}